<template>
    <div class=''>
        <h1>{{setHeaderText()}}</h1>

        <div id="progress-bar" class="pt-4 mb-4 navigatable" :style="'--steps: ' + (4 + (item.bookkeeping_system_active ? 2 : 0) + (item.reservation_system.reservation_system_id ? 1 : 0) + (item.check_daystates ? 1 : 0) - (isLoggedInAsCustomer ? 1 : 0))">
            <div v-if="!isLoggedInAsCustomer" :class="{active: activeUntil >= 1 || isProcessingCallback, current: step == 1}" @click="setStep(1)">
                <i class="far fa-industry"></i> <div>{{$t('locationtabs.step_1')}}</div>
            </div>
            <div v-if="!isLoggedInAsCustomer" :class="{active: activeUntil >= 2 || isProcessingCallback, current: step == 2}" @click="setStep(2)">
                <i class="far fa-link"></i> <div>{{$t('locationtabs.step_2')}}</div>
            </div>
            <div v-else :class="{active: activeUntil >= 2 || isProcessingCallback, current: step == 2}" @click="setStep(2)">
                <i class="far fa-industry"></i> <div>{{$t('locationtabs.step_1')}}</div>
            </div>
            <div :class="{active: activeUntil >= 3 || isProcessingCallback, current: step == 3}" @click="setStep(3)">
                <i class="far fa-receipt"></i> <div>{{$t('locationtabs.step_3')}}</div>
            </div>
            <div :class="{active: activeUntil >= 4, current: step == 4}" @click="setStep(4)" v-if="item.bookkeeping_system_active">
                <i class="far fa-arrows-rotate"></i> <div>{{$t('locationtabs.step_4')}}</div>
            </div>
            <div :class="{active: activeUntil >= 5, current: step == 5}" @click="setStep(5)" v-if="item.check_daystates">
                <i class="far fa-mobile"></i> <div>{{$tc('navigation.daystates', 2)}}</div>
            </div>
            <div :class="{active: activeUntil >= 6, current: step == 6}" @click="setStep(6)" v-if="item.bookkeeping_system_active">
                <i class="far fa-coins"></i> <div>{{ getBookkeepingName }}</div>
            </div>
            <div :class="{active: activeUntil >= 7, current: step == 7}" v-if="item.reservation_system.reservation_system_id" @click="setStep(7)">
                <i class="far fa-desktop"></i> <div>{{ getReservationName }}</div>
            </div>
            <div :class="{active: activeUntil >= 8, current: step == 8}" @click="setStep(8)">
                <i class="far fa-check default-color"></i> <div>{{$t('locationtabs.step_7')}}</div>
            </div>
        </div>

        <hr>
    </div>
    <p v-show="isLoading"><i class="fa fa-spinner fa-spin me-3"></i></p>
    <section v-if="step == 1" class="mb-5">
        <Accordion id="generaldetails" icon="fa-industry" name="locationtabs.step_1" innerClass="w-50">
            <FormItem type="select" v-model="item.hospitality_group_id" :options="hospitality_groups" :label="$t('navigation.holdings')" placeholder="-" @onchange="setGroupDealer" />
            <FormItem type="select" v-model="item.franchise_id" :options="franchises.filter(fr => fr.hospitality_group_id == item.hospitality_group_id || !item.hospitality_group_id)"
                     :label="$t('navigation.franchises')" @onchange="setFranchise" placeholder="-" />
            <FormItem type="text" v-model="item.name" :required="true" :label="$t('overview.name')" />
            <FormItem type="text" v-model="item.city" :required="true" :label="$t('location.place')" />
            <FormItem type="select" v-model="item.currency_id" :options="currencies" :label="$t('overview.currency')" :required="true" />
            <LogoUpload :item="item" />
            <FormItem type="text" v-model="item.debtor_number" :label="$t('location.debtornr')" />
            <FormItem type="select" v-model="item.dealer_id" :options="dealers" :label="$t('navigation.dealers')" placeholder="-" :disabled="isLoggedInAsDealer" />

        </Accordion>
    </section>
    <section v-if="step == 2 && isLoggedInAsCustomer" class="mb-5">
        <Accordion id="generaldetails-alt" icon="fa-industry" name="locationtabs.step_1" innerClass="w-50">
            <LogoUpload :item="item" />
        </Accordion>
    </section>
    <section v-if="step == 2 && !isLoggedInAsCustomer" class="mb-5">
        <Accordion id="connectionsource" icon="fa-desktop" name="location.source">
            <div class="w-50 pt-3 pe-2">
                <FormItem type="select" v-model="item.source_connection.source_id" :options="sources" :required="true" :label="$t('location.source_2')" />
            </div>
            <div class="d-flex gap-3 mt-2">
                <label class="ctype-label" for="ctype-1" :class="{active: item.source_connection.connection_type == 'broker'}">
                    <input type="radio" id="ctype-1" value="broker" name="ctype" v-model="item.source_connection.connection_type" />
                    <span>UniConnect Sockets <span class="pill bg-success text-light ms-2">{{$t('form.recommended')}}</span> </span>
                    <div class="ctype-details">
                        <div class="d-flex">
                            <i class="far fa-check text-success"></i> <span>{{$t('location.condesc_1')}}</span>
                        </div>
                        <div class="d-flex">
                            <i class="far fa-check text-success"></i> <span>{{$t('location.condesc_2a')}}</span>
                        </div>
                        <div class="d-flex">
                            <i class="far fa-warning text-warning"></i> <span>{{$t('location.condesc_2b')}}</span>
                        </div>
                        <div class="d-flex">
                            <i class="far fa-warning text-warning"></i> <span>{{$t('location.condesc_2c')}}</span>
                        </div>
                        <div class="text-center pt-2">
                            <img src="../../assets/sockets.png" />
                        </div>
                    </div>
                </label>
                <label class="ctype-label" for="ctype-2" :class="{active: item.source_connection.connection_type == 'direct'}">
                    <input type="radio" id="ctype-2" value="direct" name="ctype" v-model="item.source_connection.connection_type" />
                    <span>{{$t('location.direct', ['unTill'])}}</span>
                    <div class="ctype-details">
                        <div class="d-flex">
                            <i class="far fa-check text-success"></i> <span>{{$t('location.condesc_1')}}</span>
                        </div>
                        <div class="d-flex pb-2">
                            <i class="far fa-warning text-warning"></i> <span>{{$t('location.condesc_3')}}</span>
                        </div>
                        <div class="text-center pt-5">
                            <img src="../../assets/direct-api.png" />
                        </div>
                    </div>
                </label>
            </div>
            <div class="w-50 pt-3 pe-2">
                <template v-if="item.source_connection.connection_type == 'broker'">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>UUID</th>
                                <th>Hostname</th>
                                <th>Dealer</th>
                                <th>Laatste connectie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <label v-for="client_connection of item.client_connections" :key="client_connection.uuid" style="display: table-row" :for="'trx-'+client_connection.uuid">
                                <td><input type='radio' v-model="item.source_connection.broker_uuid" name="client_connection_uuid" :value="client_connection.uuid" :id="'trx-'+client_connection.uuid"></td>
                                <td>{{client_connection.uuid}}</td>
                                <td>{{client_connection.hostname}}</td>
                                <td>{{client_connection.dealer.name}}</td>
                                <td>{{formatDate(client_connection.last_connection, 'dateTime')}}</td>
                            </label>
                        </tbody>
                    </table>

                    <FormItem type="text" v-model="item.source_connection.broker_uuid" :label="$t('location.db_1')" :required="true" />

                </template>
                <template v-if="item.source_connection.connection_type == 'direct'">
                    <FormItem type="text" v-model="item.source_connection.ip_address" :label="$t('location.db_2')" :required="true" />
                    <FormItem type="text" v-model="item.source_connection.port" :label="$t('location.db_3')" :required="true" />
                </template>
                <FormItem type="text" v-model="item.source_connection.username" :label="$t('location.db_4')" :required="true" />
                <FormItem type="password" v-model="item.source_connection.password" :label="$t('location.db_5')" :required="true" />
                <FormItem type="text" v-model="item.source_connection.database_name" :label="$t('location.db_6')" :required="true" />
                <div class="row mb-2">
                    <div class="col-4">Verbinding</div>
                    <div class="col-5">
                        <span v-if="item.source_connection.status == 'pending'">
                            <i class="fa fa-spinner fa-spin me-3"></i>
                        </span>
                        <span v-else-if="item.source_connection.status == 'error'" class="text-danger">
                            <i class="fa fa-unlink me-2"></i>{{$t('location.test_connection_fail')}}<br>{{item.source_connection.status_message}}
                        </span>
                        <span v-else-if="item.source_connection.status == 'active'" class="text-success">
                            <i class="fa fa-link me-2"></i>{{$t('location.test_connection_ok')}}
                        </span>
                        <span v-else class="text-secondary">
                            <i class="fa fa-dash me-2"></i>{{$t('location.test_connection_inactive')}}
                        </span>
                    </div>
                    <FormItem type="download" :service="testConnection" :label="$t('location.test_connection')" class="col-3 text-end" style="padding-start: 14px" endTag="fal" />

                </div>
            </div>

        </Accordion>
    </section>
    <section v-if="step == 3" class="mb-5">
        <Accordion id="zero" icon="fa-receipt" name="locationtabs.step_5" v-model="item.bookkeeping_system_active" @onchange="setBookSystemOptional">
            <div class="d-flex flex-wrap w-100">
                <div class="col-3" v-for="packag in bookKeepingSystems" :key="packag.id" v-show="[undefined, null, packag.id].includes(item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id)">
                    <input class="radio-tab-3" type="radio" name="boekhoudpakket" :id="'bh-'+packag.id" :value="packag.id" v-model="item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id" @change="resetBookkeepingData" />
                    <label :for="'bh-'+packag.id" class="logo-tab-3" :style="{backgroundImage: 'url(../../interlogos/'+packag.icon+')'}">
                    </label>
                </div>
            </div>
            <button type="button" v-if="item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id" class="btn btn-unidark" @click="resetBookkeepingData(false)">{{$t('location.bookkeeping_change')}}</button>
            <hr>
            <h2 class="pb-2" v-show="item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id">{{$t('location.data_x', [getBookkeepingName])}}</h2>
            <template v-if="getBookkeepingName == 'Exact Online'">
                <FormItem type="select" v-model="item.bookkeeping_system_location.country" :options="[{id: 'nl', name: 'Nederlands'}]" :required="true" :label="$t('overview.language')" sizeLabel="2" sizeItem="3" />
                <div class="row mb-2">
                    <label class="col-sm-2 required">Authorisatie</label>
                    <template v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                        <div class="col-2">
                            <input type="text" readonly class="form-control-plaintext text-success" style="border: 0px" :value="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                        </div>
                        <div class="col-3">
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button> &nbsp;
                            <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">Vernieuwen</button>
                        </div>
                    </template>
                    <div v-else class="col-3">
                        <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">{{$t('location.connect_start')}}</button>
                    </div>
                </div>
                <div class="row mb-2" v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                    <label class="col-sm-2">{{$t('location.administration')}}</label>
                    <div class="col-sm-2">
                        <FormItem v-if="bookkeepingDivisions.length > 0" type="select" v-model="selectedDivision" :options="bookkeepingDivisions" :required="true" :label="false" sizeLabel="2" sizeItem="3" idField="code" nameField="description" />
                        <input v-else type="text" readonly class="form-control-plaintext" style="border: 0px" :value="item.bookkeeping_system_location.division_name">
                    </div>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <button v-else-if="!bookkeepingDivisions.length" class="btn btn-sm btn-unidark btn-connect" type="button" @click="getDivisions(1)">Administratie wisselen </button>
                    </div>
                </div>
            </template>

            <template v-else-if="getBookkeepingName == 'Twinfield'">
                <div class="row mb-2">
                    <label class="col-sm-2 required">Authorisatie</label>
                    <template v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                        <div class="col-2">
                            <input type="text" readonly class="form-control-plaintext text-success" style="border: 0px" :value="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                        </div>
                        <div class="col-3">
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button> &nbsp;
                            <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">Vernieuwen</button>
                        </div>
                    </template>
                    <div v-else class="col-3">
                        <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">{{$t('location.connect_start')}}</button>
                    </div>
                </div>
                <div class="row mb-2" v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                    <label class="col-sm-2">{{$t('location.administration')}}</label>
                    <div class="col-sm-2">
                        <FormItem v-if="bookkeepingDivisions.length > 0" type="select" v-model="selectedDivision" :options="bookkeepingDivisions" :required="true" :label="false" sizeLabel="2" sizeItem="3" idField="code" nameField="description" />
                        <input v-else type="text" readonly class="form-control-plaintext" style="border: 0px" :value="item.bookkeeping_system_location.division_name">
                    </div>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <button v-else-if="!bookkeepingDivisions.length" class="btn btn-sm btn-unidark btn-connect" type="button" @click="getDivisions(1)">Administratie wisselen </button>
                    </div>
                </div>

            </template>
            <template v-else-if="getBookkeepingName == 'Visma e-accounting'">
                <div class="row mb-2">
                    <label class="col-sm-2 required">Authorisatie</label>
                    <template v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                        <div class="col-2">
                            <input type="text" readonly class="form-control-plaintext text-success" style="border: 0px" :value="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                        </div>
                        <div class="col-3">
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button> &nbsp;
                            <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">Vernieuwen</button>
                        </div>
                    </template>
                    <div v-else class="col-3">
                        <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">{{$t('location.connect_start')}}</button>
                    </div>
                </div>
                <div class="row mb-2" v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                    <label class="col-sm-2">{{$t('location.administration')}}</label>
                    <div class="col-sm-2">
                        <FormItem v-if="bookkeepingDivisions.length > 0" type="select" v-model="selectedDivision" :options="bookkeepingDivisions" :required="true" :label="false" sizeLabel="2" sizeItem="3" idField="code" nameField="description" />
                        <input v-else type="text" readonly class="form-control-plaintext" style="border: 0px" :value="item.bookkeeping_system_location.division_name">
                    </div>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <button v-else-if="!bookkeepingDivisions.length" class="btn btn-sm btn-unidark btn-connect" type="button" @click="getDivisions(1)">Administratie wisselen </button>
                    </div>
                </div>

            </template>

            <template v-else-if="getBookkeepingName == 'Yuki'">
                <FormItem type="text" v-model="item.bookkeeping_system_location.bookkeeping_system_auth.api_key" :label="$t('location.integration_apikey')"  sizeLabel="2" sizeItem="3" :required="true" placeholder="Token"/>
                <div class="row mb-2">
                    <label class="required col-sm-2">Authorisatie</label>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <template v-else-if="item.bookkeeping_system_location.division_code">
                            <span class="text-success d-inline-block w-50">Koppeling actief</span>
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button>
                        </template>
                        <template v-else>
                            <button class="btn btn-sm btn-unidark btn-connect" type="button" @click="getCurrentDivision()">{{$t('location.connect_start')}}</button>
                            <span v-if="bookkeepingStatusResult == 'success'" class="text-success">Koppeling actief</span>
                            <span v-else-if="bookkeepingStatusResult == 'error'" class="text-danger">Fout bij verbinden</span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2" v-if="item.bookkeeping_system_location.division_code" >
                    <label class="col-sm-2">{{$t('location.administration')}}</label>
                    <div class="col-sm-2">
                        <FormItem v-if="bookkeepingDivisions.length > 0" type="select" v-model="selectedDivision" :options="bookkeepingDivisions" :required="true" :label="false" sizeLabel="2" sizeItem="3" idField="code" nameField="description" />
                        <input v-else type="text" readonly class="form-control-plaintext optional" style="border: 0px" :value="item.bookkeeping_system_location.division_name">
                    </div>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <button v-else-if="!bookkeepingDivisions.length" class="btn btn-sm btn-unidark btn-connect" type="button" @click="getDivisions(1)">Administratie wisselen </button>
                    </div>
                </div>
            </template>
            <template v-else-if="getBookkeepingName == 'Afas'">

              <FormItem type="text" v-model="item.bookkeeping_system_location.bookkeeping_system_auth.environment_code" :label="$t('location.integration_environment')" sizeLabel="2" sizeItem="4" :required="true" placeholder="O12345AA" />
              <FormItem type="text" v-model="item.bookkeeping_system_location.bookkeeping_system_auth.access_token" :label="$t('location.integration_accesstoken')" sizeLabel="2" sizeItem="4" :required="true" placeholder="<token><version>1</version><data>A896D0E77123489691C9FFDB6F4862DFF7910DEBF4EF4CC4AFA5979EC25F0C1F</data></token>"/>
              <FormItem type="text" v-model="item.bookkeeping_system_location.division_code" :label="$t('location.integration_division')" sizeLabel="2" sizeItem="4" :required="true"  placeholder="1"/>

              <div class="row mb-2">
                    <label class="required col-sm-2">Authorisatie</label>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <template v-else-if="item.bookkeeping_system_location.division_code">
                            <span class="text-success d-inline-block w-50">Koppeling actief</span>
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button>
                        </template>
                        <template v-else>
                            <button class="btn btn-sm btn-unidark btn-connect w-100" type="button" @click="getCurrentDivision()">{{$t('location.connect_start')}}</button>
                            <span v-if="bookkeepingStatusResult == 'success'" class="text-success">Koppeling actief</span>
                            <span v-else-if="bookkeepingStatusResult == 'error'" class="text-danger">Fout bij verbinden</span>
                        </template>
                    </div>
                </div>

            </template>

            <template v-else-if="getBookkeepingName == 'Unit4'">
                <div class="row flex-wrap w-100 pt-2">
                    <label class="col-sm-2 required">Authorisatie</label>
                    <div class="col-sm-4">
                        <template v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                            <span class="text-success d-inline-block w-50">{{item.bookkeeping_system_location.bookkeeping_system_auth.username}}</span>
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button>
                        </template>
                        <button v-else class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">{{$t('location.connect_start')}}</button>
                    </div>
                </div>
                <div class="row mb-2" v-if="item.bookkeeping_system_location.division_code" >
                    <label class="col-sm-2">{{$t('location.administration')}}</label>
                    <div class="col-sm-2">
                        <FormItem v-if="bookkeepingDivisions.length > 0" type="select" v-model="selectedDivision" :options="bookkeepingDivisions" :required="true" :label="false" sizeLabel="2" sizeItem="3" idField="code" nameField="description" />
                        <input v-else type="text" readonly class="form-control-plaintext optional" style="border: 0px" :value="item.bookkeeping_system_location.division_name">
                    </div>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <button v-else-if="!bookkeepingDivisions.length" class="btn btn-sm btn-unidark btn-connect" type="button" @click="getDivisions(1)">Administratie wisselen </button>
                    </div>
                </div>
            </template>
            <template v-else-if="getBookkeepingName == 'Snelstart'">
                <div class="row flex-wrap w-100 pt-2">
                    <label class="col-sm-2 required">{{ getBookkeepingName }} koppeling</label>
                    <div class="col-sm-4">
                        <template v-if="item.bookkeeping_system_location.bookkeeping_system_auth.username">
                            <span class="text-success d-inline-block w-50">{{item.bookkeeping_system_location.bookkeeping_system_auth.username}}</span>
                            <button class="btn btn-sm btn-unidark btn-unlink" type="button" @click="removeBookkeeping()">{{$t('location.connect_end')}}</button>
                        </template>
                        <button v-else class="btn btn-sm btn-unidark btn-connect" type="button" @click="oauth(false)">{{$t('location.connect_start')}}</button>
                    </div>
                </div>
                <div class="row mb-2" v-if="item.bookkeeping_system_location.division_code" >
                    <label class="col-sm-2">{{$t('location.administration')}}</label>
                    <div class="col-sm-2">
                        <FormItem v-if="bookkeepingDivisions.length > 0" type="select" v-model="selectedDivision" :options="bookkeepingDivisions" :required="true" :label="false" sizeLabel="2" sizeItem="3" idField="code" nameField="description" />
                        <input v-else type="text" readonly class="form-control-plaintext optional" style="border: 0px" :value="item.bookkeeping_system_location.division_name">
                    </div>
                    <div class="col-sm-3">
                        <p v-if="isGettingDivision"><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</p>
                        <button v-else-if="!bookkeepingDivisions.length" class="btn btn-sm btn-unidark btn-connect" type="button" @click="getDivisions(1)">Administratie wisselen </button>
                    </div>
                </div>
            </template>

        </Accordion>

        <Accordion id="accordionRS" icon="fa-calendar-check" name="locationtabs.step_6" v-model="item.reservation_system_active" @onchange="setResSystemOptional">
            <div class="d-flex">
                <div class="col-3 mb-2" v-for="rsys in reservationSystems" :key="rsys.id">
                    <input type="radio" name="reservsysteem" :id="'rs-'+rsys.id" :value="rsys.id" v-model="item.reservation_system.reservation_system_id" @click="setReservation" />
                    <label :for="'rs-'+rsys.id" class="d-inline"> <img :src="'../../interlogos/'+rsys.icon" height="60" /> </label>
                </div>
            </div>
            <div class="w-50 pt-4" v-if="item.reservation_system.reservation_system_id == 1">
                <FormItem type="text" id="miceapikey" v-model="item.reservation_system.reservation_system_auth.api_key" label="API Key" :required="true" />
            </div>
            <div class="w-50 pt-4" v-else-if="item.reservation_system.reservation_system_id == 2">
                <FormItem type="text" id="semhost" v-model="item.reservation_system.reservation_system_auth.host" label="Host" :required="true" />
                <FormItem type="text" id="semusername" v-model="item.reservation_system.reservation_system_auth.username" :label="$t('location.db_4')" :required="true" />
                <FormItem type="password" id="sempassword" v-model="item.reservation_system.reservation_system_auth.password" :label="$t('location.db_5')" :required="true" />
                <FormItem type="text" id="semcc" v-model="item.reservation_system.reservation_system_auth.company_code" label="Company code" />
            </div>
            <div class="w-50 pt-4" v-if="item.reservation_system.reservation_system_id">
                <div class="row mb-2">
                    <div class="col-4">{{$t('locationtabs.step_2')}}</div>
                    <div class="col-5 pt-1">
                        <span v-if="item.reservation_system.status == 'pending'">
                            <i class="fa fa-spinner fa-spin me-3"></i>
                        </span>
                        <span v-else-if="item.reservation_system.status == 'error'" class="text-danger">
                            <i class="fa fa-unlink me-2"></i>{{$t('location.test_connection_fail')}}<br>{{item.reservation_system.status_message}}
                        </span>
                        <span v-else-if="item.reservation_system.status == 'active'" class="text-success">
                            <i class="fa fa-link me-2"></i>{{$t('location.test_connection_ok')}}
                        </span>
                        <span v-else class="text-secondary">
                            <i class="fa fa-dash me-2"></i>{{$t('location.test_connection_inactive')}}
                        </span>
                    </div>
                    <FormItem type="download" :service="testReservationConnection" :label="$t('location.test_connection')" class="col-3 text-end" style="padding-start: 14px" endTag="fal" />
                </div>
            </div>
        </Accordion>
    </section>
    <section v-if="step == 4" class="mb-5">
        <Accordion id="syncfunctions" icon="fa-arrows-rotate" name="locationtabs.step_4">
            <p>Binnen {{ getBookkeepingName }} heeft u verschillende opties voor synchronisatie. Geef hieronder op welke onderdelen u uit uw dagstaat gesynchonsieerd wil hebben.</p>
            <div class="row mb-4">
                <label class="d-flex" style="width: 10rem">{{$t('location.day_start')}}* &nbsp;
                    <i class="fa fa-info-circle text-warning pt-1"></i>
                    <div class="tooltip bs-tooltip-auto" data-popper-placement="right">
                        <div class="tooltip-arrow" style="position: absolute; top: 0px; transform: translate3d(0px, 7.2px, 0px)"></div>
                        <div class="tooltip-inner">{{$t('location.day_starttime')}}</div>
                    </div>
                </label>
                <div class="d-flex justify-content-end" style="width: 10rem">
                    <FormItem type="select" v-model="dayStartHour" :options="hours" :required="true" style="min-width: 55px" @onchange="updateDayStart" />
                    <span style="align-self: center">&nbsp;:&nbsp;</span>
                    <FormItem type="select" v-model="dayStartMinute" :options="minutes" :required="true" style="min-width: 55px" @onchange="updateDayStart" />
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-1" type="slider" v-model="item.sync_paid_only" :options="[0,1]" />
                    <label class="col" for="sync-1">
                        <span>Betaald/Besteld synchroniseren</span>
                        <p class="fw-normal pt-2">Synchronisatie van de status betaald/besteld.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-2" type="slider" v-model="item.split_on_costcenter" :options="[1,0]" />
                    <label for="sync-2" class="col">
                        <span>Splitsing kostenplaats</span>
                        <p class="fw-normal pt-2">Kostenplaatsen worden gesplitst.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-3" type="slider" v-model="item.split_on_division" :options="[1,0]" />
                    <label for="sync-3" class="col">
                        <span>Splitsing administratie</span>
                        <p class="fw-normal pt-2">Administratie wordt gesplitst.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-4" type="slider" v-model="item.sync_cards_vouchers" :options="[1,0]" />
                    <label for="sync-4" class="col">
                        <span>Stortingen klanten/klantenkaarten</span>
                        <p class="fw-normal pt-2">Optie voor stortingen van klanten en klantenkaarten.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-5" type="slider" v-model="item.sync_debtors" :options="[1,0]" />
                    <label for="sync-5" class="col">
                        <span>Debiteuren synchronisatie</span>
                        <p class="fw-normal pt-2">Synchronisatie van debiteuren waar één of meer facturen op zijn gemaakt.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-7" type="slider" v-model="item.create_url_for_day_closure" :options="[1,0]" />
                    <label class="col" for="sync-7">
                        <span>Gebruik dagsluiting</span>
                        <p class="fw-normal pt-2">Externe toegang voor direct afsluiten van dagstaten via de kassa.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-9" type="slider" v-model="item.show_tip" :options="[1,0]" />
                    <label for="sync-9" class="col">
                        <span>Fooien inzichtelijk</span>
                        <p class="fw-normal pt-2">Weergave van fooien zichtbaar maken bij de dagstaat.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5" v-if="item.sync_debtors">
                    <FormItem class="pe-2" id="sync-8" type="slider" v-model="item.sync_invoices" :options="[1,0]" />
                    <label for="sync-8" class="col">
                        <span>Facturen synchroniseren</span>
                        <p class="fw-normal pt-2">Verwerken van factuurnummers bij de dagstaten.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5" v-if="getBookkeepingName == 'Afas'">
                    <FormItem class="pe-2" id="sync-afas" type="slider" v-model="item.book_withdrwals" :options="[1,0]" />
                    <label for="sync-afas" class="col">
                        <span>Afschrijvingen boeken</span>
                        <p class="fw-normal pt-2">Afschrijvingen van producten verwerken in AFAS.</p>
                    </label>
                </div>
            </div>
        </Accordion>
        <Accordion id="dsdaystate" icon="fa-calculator" name="daystate.daystate_check" v-model="item.check_daystates">
            <p class="fw-normal pt-2">Een dagstaatcontrole houd in dat dagelijks de dagstaat door uw personeel moet worden gecontroleerd om zo de kasopmaak te kunnen verantwoorden.</p>
            <FormItem class="pe-2 pb-1" id="sync-date" type="date" v-model="item.daystate_check_date" label="Controle vanaf" sizeItem="2" sizeLabel="2" />
            <div class="d-flex flex-wrap">
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-d1" type="slider" v-model="hasCashCounting" :options="[1,0]" @onchange="hasCashAccountability = 0" />
                    <label class="col" for="sync-d1">
                        <span>Kastelling</span>
                        <p class="fw-normal pt-2">Bij kastelling wordt het totale bedrag aan contanten in de kassa geteld. Dit omvat zowel de ontvangsten als het wisselgeld, en het doel is om te controleren of het fysieke kassaldo overeenkomt met de administratieve gegevens.</p>
                    </label>
                </div>
                <div class="col-4 d-flex align-items-start pe-5">
                    <FormItem class="pe-2" id="sync-d2" type="slider" v-model="hasCashAccountability" :options="[1,0]" @onchange="hasCashCounting = 0" />
                    <label for="sync-d2" class="col">
                        <span>Kasverantwoording</span>
                        <p class="fw-normal pt-2">Kasverantwoording richt zich op het toewijzen van eventuele verschillen aan specifieke personen of afdelingen. Hierbij is het getelde contante bedrag niet hetzelfde als het bedrag dat naar de Grote Kluis wordt overgemaakt.</p>
                    </label>
                </div>
            </div>
        </Accordion>
        <Accordion id="dsone" icon="fa-credit-card" name="daystate.card_vouchers" v-if="item.sync_cards_vouchers">
            <div class="row">
                <div class="col-4"></div>
                <div class="col" v-for="ctype in cardVoucherTypes" :key="ctype.name">
                    <div class="fw-bold">{{ctype.name}}</div>
                </div>
            </div>
            <div class="row" v-for="(card, cindex) in item.location_card_and_vouchers" :key="cindex">
                <template v-if="cardVouchers[cindex]">
                    <div class="col-4 d-flex">
                        <div> <FormItem type="slider" v-model="item[cardVouchers[cindex].field]" :options="[1,0]" /> </div>
                        <div class="pt-2 ps-2 fw-bold">{{cardVouchers[cindex].name}}</div>
                    </div>
                    <div class="col" style="max-width: 25%" v-for="cardlistitem, cliId in cardVoucherTypes" :key="cliId">
                        <FormItem type="livesearch" :options="bookkeepingGlAccounts" v-model="card[cardlistitem.field]" :disabled="!item[cardVouchers[cindex].field]" :required="item[cardVouchers[cindex].field]"
                                :content="safeFind(card[cardlistitem.field], bookkeepingGlAccounts, 'glaccount')" @onchange="(a,b,c) => setExternalId(card, c, cardlistitem.field)" />
                    </div>
                </template>
            </div>
        </Accordion>
        <Accordion id="saleszones" icon="fa-box-dollar" name="location.sales_areas" v-if="item.split_on_costcenter || item.split_on_division">
            <div class="d-flex pb-2 fw-bold">
                <div class="col px-1">{{$t('overview.number')}}</div>
                <div class="col px-1 fw-bold">{{$t('overview.name')}}</div>
                <div class="col px-1" v-show="item.split_on_costcenter">{{$t('location.cost_place')}}</div>
                <div class="col px-1" v-show="item.split_on_division">{{$t('location.administration')}}</div>
            </div>
            <div class="d-flex pt-1" v-for="zone in item.sales_areas" :key="zone.name">
                <div class="col px-1">{{zone.external_number}}</div>
                <div class="col px-1">{{zone.external_name}}</div>
                <div class="col px-1" v-show="item.split_on_costcenter">
                    <FormItem type="text" v-model="zone.cost_center_name" />
                </div>
                <div class="col px-1" v-show="item.split_on_division">
                    <FormItem type="livesearch" v-model="zone.external_divison" v-if="bookkeepingDivisions.length" :options="bookkeepingDivisions" idField="code" nameField="description" 
                             :content="safeFind(zone.external_divison, bookkeepingDivisions, 'code', 'description')" />
                    <FormItem type="text" v-model="zone.external_divison" v-else />
                </div>
            </div>
        </Accordion>
    </section>
    <section v-if="step == 5" class="mb-5">
        <div class='row pt-3'>
            <Accordion id="dszero" icon="fa-gear" name="form.settings">
                <FormItem id="dssettings-1" type="slider" v-model="item.responsible_person_required" :label="$t('daystate.check_mandatory')" :options="[1,0]" sizeLabel="3" sizeItem="1" />
            </Accordion>
            <Accordion id="dstwo" icon="fa-mobile" name="location.res_paymethods_daystates">
                <div class="col pe-1" v-for="(paymethod, pindex) in paymentMethodDetailsDaystate" :key="paymethod.name">
                    <hr v-show="pindex != 0" class="bg-secondary" />
                    <div class="col-12 fw-bold">{{paymethod.name}}</div>
                    <div class="d-flex pt-1">
                        <div class="paymethod-grid col-10">
                            <div v-for="(listItem, lid) in paymethod.list" :key="listItem" class="pe-5">
                                <button class="btn btn-sm text-danger pt-0 px-1" type="button" @click="removePayMethod(paymethod.list, lid)"> <i class="fa fa-close"></i> </button> {{listItem.name}}
                            </div>
                        </div>
                        <div class="col-2 text-end">
                            <button type="button" class="btn btn-unidark btn-sm" @click="showPayMethodPopup(paymethod, true)"> <i class="fa fa-plus"></i> </button>
                        </div>
                    </div>
                </div>
            </Accordion>
            <Accordion id="dsthree" icon="fa-mobile" name="daystate.terminal_cash" innerClass="d-flex w-100">
                <div class="w-50 pe-4">
                    <h3>{{$t('location.terminal_name')}}</h3>
                    <div class="d-flex mb-1" v-for="terminalName, tindex in item.payment_terminals" :key="tindex">
                        <div class="col-9"> <FormItem type="text" v-model="terminalName.name" /> </div>
                        <div class="col-auto ms-2" draggable="true"> <i class="far fa-arrows-up-down"></i> </div>
                        <div class="col-auto">
                            <button type="button" class="btn text-danger btn-sm mt-1" @click="removeName(item.payment_terminals, tindex)"> <i class="fa fa-close"></i> </button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-unidark mt-1" @click="addName(item.payment_terminals)"> <i class="fa fa-plus pe-2"></i> {{$t('form.add', ["Terminal"])}} </button>
                </div>
                <div class="w-50 pe-4">
                    <h3>{{$t('location.cash_name')}}</h3>
                    <div class="d-flex mb-1" v-for="cashRegName, cgindex in item.cash_registers" :key="cgindex">
                        <div class="col-9"> <FormItem type="text" v-model="cashRegName.name" /> </div>
                        <div class="col-auto ms-2" draggable="true"> <i class="far fa-arrows-up-down"></i> </div>
                        <div class="col-auto">
                            <button type="button" class="btn text-danger btn-sm" @click="removeName(item.cash_registers, cgindex)"> <i class="fa fa-close"></i> </button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-unidark mt-1" @click="addName(item.cash_registers)"> <i class="fa fa-plus pe-2"></i> {{$t('form.add', ["Kassa"])}} </button>
                </div>
            </Accordion>
            <Accordion id="dsfour" icon="fa-mobile" name="daystate.suppliers">
                <div class="d-flex fw-bold pb-2">
                    <div class="col px-1">{{$t('overview.name')}}</div>
                    <div class="col px-1">{{$t('location.invnr')}}</div>
                    <div class="col px-2">{{$t('location.remove_from_safe')}}</div>
                </div>
                <div class="d-flex pt-1" v-for="(supplier, sindex) in item.suppliers" :key="supplier.name">
                    <div class="col px-1">{{supplier.name}}</div>
                    <div class="col">
                        <FormItem type="livesearch" :id="'salesgl-'+sindex" v-model="supplier.external_glaccount_id" :options="bookkeepingGlAccounts" class="w-75" :required="true"
                                 :content="safeFind(supplier.external_glaccount_id, bookkeepingGlAccounts, 'glaccount')" @onchange="(a,b,c) => supplier.glaccount_name = c.name" />
                    </div>
                    <div class="col px-1">
                        <FormItem style="width: 56px; position:relative; bottom: 2px" :id="'salesos-'+sindex" type="slider" v-model="supplier.withdraw_from_safe" :options="[1,0]" />
                    </div>
                </div>
            </Accordion>
        </div>
    </section>
    <section v-if="step == 6" class="mb-5">
        <div class='row pt-3'>
            <div class="col-6">
                <h2> <i class="far fa-desktop me-2 fs-15"></i>{{ getBookkeepingName }} </h2>
            </div>
            <div class="col-6 text-end">
                <button v-if="isGettingBookkeepingData" class="btn btn-sm btn-unidark btn-import" type="button" disabled><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</button>
                <button v-else class="btn btn-sm btn-unidark btn-import" type="button" @click="getBookkeepingData()">Gegevens boekhoudpakket updaten</button>
            </div>
        </div>

        <Accordion id="one" icon="fa-file" name="location.step_5a">
            <div class="row mb-2">
                <label class="d-flex required col-sm-3">{{$t('location.bookkeeping_autosync')}}</label>
                <div class="col-sm-2 d-flex" style="justify-content: space-between;">
                    <FormItem id="bookkeeping_autosync" type="slider" v-model="item.bookkeeping_autosync" :options="[1,0]" />
                </div>
            </div>

            <FormItem :type="determineType()" id="journal" v-model="item.bookkeeping_system_location.journal" :options="journals" :label="$t('location.daybook')"
                      idField="code" sizeItem="3" sizeLabel="3" :content="safeFind(item.bookkeeping_system_location.journal, journals, 'code')" />
            <FormItem type="livesearch" id="journalgl" v-model="item.bookkeeping_system_location.journal_glaccount" :options="bookkeepingGlAccounts" :label="$t('location.dayb_acc')"
                      idField="glaccount" sizeItem="3" sizeLabel="3" :content="safeFind(item.bookkeeping_system_location.journal_glaccount, bookkeepingGlAccounts, 'glaccount')" @onchange="(a,b,c) => setExternalId(item.bookkeeping_system_location, c, 'journal_glaccount')" />
            <FormItem type="livesearch" id="crosspost" v-model="item.bookkeeping_system_location.crosspost_glaccount" :options="bookkeepingGlAccounts" :label="$t('location.cross')"
                      idField="glaccount" sizeItem="3" sizeLabel="3" :content="safeFind(item.bookkeeping_system_location.crosspost_glaccount, bookkeepingGlAccounts, 'glaccount')" @onchange="(a,b,c) => setExternalId(item.bookkeeping_system_location, c, 'crosspost_glaccount')" />
            <FormItem type="livesearch" id="defaultgl" v-model="item.bookkeeping_system_location.default_glaccount" :options="bookkeepingGlAccounts" :label="$t('location.glaccount')"
                      idField="glaccount" sizeItem="3" sizeLabel="3" :content="safeFind(item.bookkeeping_system_location.default_glaccount, bookkeepingGlAccounts, 'glaccount')" @onchange="(a,b,c) => setExternalId(item.bookkeeping_system_location, c, 'default_glaccount')" />
            <FormItem v-if="item.show_tip" type="livesearch" id="defaulttip" v-model="item.bookkeeping_system_location.tip_glaccount" :options="bookkeepingGlAccounts" :label="$t('location.tip_glaccount')"
                      idField="glaccount" sizeItem="3" sizeLabel="3" :content="safeFind(item.bookkeeping_system_location.tip_glaccount, bookkeepingGlAccounts, 'glaccount')" @onchange="(a,b,c) => setExternalId(item.bookkeeping_system_location, c, 'tip_glaccount')" />
            <div class="d-flex">
                <label class="col-3"></label>
                <label class="col-3 ps-2">{{$t('location.invnr')}}</label>
                <label class="col-2 ps-3">{{$t('form.vat')}}</label>
            </div>
            <FormItem type="livesearch" id="defaultglx" v-model="item.default_paymethod" :options="bookkeepingGlAccounts" :label="$t('location.step_5b_default')" 
                      sizeItem="3" sizeLabel="3" :content="safeFind(item.default_paymethod_glaccount, bookkeepingGlAccounts, 'glaccount')" :required="true" />
            <div class="d-flex pb-2">
                <label class="col-3">{{$t('location.step_5c_default')}}<span class='text-muted'>*</span></label>
                <FormItem class="col-3" style="padding-start: 5px; padding-end: 11px" type="livesearch" v-model="item.default_turnover" :options="bookkeepingGlAccounts"
                          sizeItem="3" :content="safeFind(item.default_turnover_glaccount, bookkeepingGlAccounts, 'glaccount')"  id="defaultgly" :required="true" />
                <FormItem class="col-2 ps-3" type="livesearch" v-model="item.default_vat" :options="vatTypes" sizeItem="3"
                         :content="safeFind(item.default_vatcode, vatTypes, 'code')" id="defaultglz" :required="true" />
            </div>
        </Accordion>
        <Accordion id="two" icon="fa-wallet" name="location.step_5b">
            <div class="d-flex">
                <label class="col-3"></label>
                <label class="col-3 ps-2">{{$t('location.invnr')}}</label>
                <label class="col-1 ps-1">{{$t('location.check_eft_split')}}</label>
                <label class="col-1" style="width: 60px"></label>
                <label class="col-2 ps-1">{{$t('location.check_tip_atm')}}</label>
                <div class="col-auto text-end" style="width: calc(25% - 60px)">
                    <button v-if="isGettingPaymentMethods" class="btn btn-sm btn-unidark btn-import" type="button" disabled><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</button>
                    <button v-else class="btn btn-sm btn-unidark btn-import" type="button" @click="updatePaymentMethods()">Betaalwijzes ophalen</button>
                </div>
            </div>

            <div class="d-flex pb-2" v-for="pmethod in item.payment_methods" :key="pmethod.id">
                <label class="col-3">{{pmethod.name}}</label>
                <FormItem type="livesearch" v-model="pmethod.bookkeeping_glaccount" :options="bookkeepingGlAccounts" class="col-3 px-2"
                         :content="safeFind(pmethod.bookkeeping_glaccount, bookkeepingGlAccounts, 'glaccount')" :id="'paym-' + pmethod.id" />
                <FormItem type="slider" v-model="pmethod.split_eft" style="width: 60px" :id="'paymx-' + pmethod.id" :options="[1,0]" />
                <div class="col-1"></div>
                <FormItem type="slider" v-model="pmethod.atm_has_tip" style="width: 60px" :id="'paymx-' + pmethod.id" :options="[1,0]" />
            </div>
        </Accordion>
        <Accordion id="three" icon="fa-book" name="location.step_5c">
            <div class="d-flex">
                <label class="col-3"></label>
                <label class="col-3 ps-2">{{$t('location.invnr')}}</label>
                <label class="col-2 ps-3">{{$t('form.vat')}}</label>
                <div class="col-4 text-end">
                    <button v-if="isGettingTurnoverGroups" class="btn btn-sm btn-unidark btn-import" type="button" disabled><i class="fa fa-spinner fa-spin me-3"></i>{{$t('form.please_wait')}}</button>
                    <button v-else class="btn btn-sm btn-unidark btn-import" type="button" @click="updateTurnoverGroups()">Omzetgroepen ophalen</button>
                </div>
            </div>
            <div class="d-flex pb-2" v-for="turnover_group in item.turnover_groups" :key="turnover_group.id">
                <label class="col-3">{{turnover_group.name}}</label>
                <FormItem class="col-3 px-2" type="livesearch" v-model="turnover_group.bookkeeping_glaccount" :options="bookkeepingGlAccounts" sizeItem="3"
                            :content="safeFind(turnover_group.bookkeeping_glaccount, bookkeepingGlAccounts, 'glaccount')" :id="'turnover-gl-' + turnover_group.id" />
                <FormItem class="col-2 ps-3" type="livesearch" v-model="turnover_group.bookkeeping_vatcode" :options="vatTypes" sizeItem="3"
                            :content="safeFind(turnover_group.bookkeeping_vatcode, vatTypes, 'code')" :id="'turnover-vat-' + turnover_group.id" />
            </div>
        </Accordion>
        <Accordion id="four" icon="fa-wallet" name="location.step_5d">
            <!--<FormItem id="vatcalcuntill" type="slider" v-model="item.bookkeeping_system_location.calc_vat_by_untill" :label="$t('location.vat_1')" sizeLabel="3" sizeItem="3" :options="[1,0]" />
            <FormItem id="vatdeviates" type="slider" v-model="item.bookkeeping_system_location.alternate_vat" :label="$t('location.vat_2')" sizeLabel="3" sizeItem="3" :options="[1,0]" />
            <FormItem type="text" v-model="item.bookkeeping_system_location.vat_code_low" :label="$t('location.vat_3')" sizeLabel="3" sizeItem="3" />
            <FormItem type="text" v-model="item.bookkeeping_system_location.vat_code_high" :label="$t('location.vat_4')" sizeLabel="3" sizeItem="3" />-->
            <FormItem id="vatsplit" type="slider" v-model="item.bookkeeping_system_location.vat_per_costcenter" :label="$t('location.vat_5')" sizeLabel="3" sizeItem="3" :options="[1,0]" />
        </Accordion>
    </section>
    <section v-if="step == 7 && (getReservationName == 'Mice' || getReservationName == 'SEM')" class="mb-5">
        <Accordion id="sixone" icon="fa-calendar-check" name="location.res_connect" :nameArgument="getReservationName" innerClass="d-flex w-100">
            <div class="w-50 pe-4">
                <FormItem type="livesearch" id="rtex-1" v-model="item.reservation_system.external_sales_area_id" :options="posSalesAreas" :label="$t('location.res_connect_a1')"
                    idField="id" :content="safeFind(item.reservation_system.external_sales_area_id, posSalesAreas, 'id')" />
                <FormItem type="number-range" id="rtex-2" v-model="item.reservation_system.tablenumber_min" :label="$t('location.res_connect_a2')"
                         :options="{min: tableMin, max: item.reservation_system.tablenumber_max}" :infoIconText="$t('form.between', [tableMin, item.reservation_system.tablenumber_max])" />
                <FormItem type="number-range" id="rtex-3" v-model="item.reservation_system.tablenumber_max" :label="$t('location.res_connect_a3')"
                         :options="{min: item.reservation_system.tablenumber_min, max: tableMax}" :infoIconText="$t('form.between', [item.reservation_system.tablenumber_min, tableMax])" />
                <br />
                <FormItem type="livesearch" id="rtex-4" v-model="item.reservation_system.external_initial_order_id" :options="posArticles" :label="$t('location.res_connect_a4')"
                          idField="id" :content="safeFind(item.reservation_system.external_initial_order_id, posArticles, 'id')" />
                <!-- <FormItem type="text" id="rtex-5" v-model="item.reservation_system.initial_order_name" :label="$t('location.res_connect_a5')" /> -->
                <FormItem type="livesearch" id="rtex-6" v-model="item.reservation_system.external_payment_id" :options="item.payment_methods" :label="$t('location.res_connect_a6_' + getReservationName )"
                          idField="source_payment_id" :content="safeFind(item.reservation_system.external_payment_id, item.payment_methods, 'source_payment_id')" />
                <br />
                <FormItem type="text" id="rtex-8" v-model="item.reservation_system.external_unknown_product_id" :label="$t('location.res_connect_a8_' + getReservationName)" />
            </div>
            <div class="w-50 ps-4">
                <FormItem id="rcon-1" type="slider" v-model="item.reservation_system.sync_deposit" :label="$t('location.res_connect_b1')" :options="[1,0]" sizeLabel="9" sizeItem="3" />
                <FormItem type="livesearch" id="rtex-7" v-model="item.reservation_system.external_deposit_payment_id" :options="item.payment_methods"  :label="$t('location.res_connect_a7')"
                          idField="source_payment_id" :content="safeFind(item.reservation_system.external_deposit_payment_id, item.payment_methods, 'source_payment_id')"
                          sizeLabel="6" sizeItem="6" v-if="item.reservation_system.sync_deposit" />
                <FormItem id="rcon-2" type="slider" v-model="item.reservation_system.sync_products" :label="$t('location.res_connect_b2')" :options="[1,0]" sizeLabel="9" sizeItem="3" />
                <FormItem id="rcon-3" type="slider" v-model="item.reservation_system.sync_payments" :label="$t('location.res_connect_b3')" :options="[1,0]" sizeLabel="9" sizeItem="3" />
                <FormItem id="rcon-4" type="slider" v-model="item.reservation_system.slider_4" :label="$t('location.res_connect_b4')" :options="[1,0]" sizeLabel="9" sizeItem="3" v-if="getReservationName == 'Mice'" />
                <FormItem id="rcon-5" type="slider" v-model="item.reservation_system.slider_5" :options="[1,0]" :label="$t('location.res_connect_b5')" infoIconText="TEST" v-if="getReservationName == 'Mice'" />
            </div>
        </Accordion>
        <Accordion id="sixtwo" icon="fa-calendar-check" name="location.res_groups" :nameArgument="getReservationName">
            <div class="d-flex gap-3">
                <div class="col-3"></div>
                <div class="col fw-bold" v-for="vatType in vatTypesArray" :key="vatType">{{vatType}}%</div>
            </div>
            <div class="d-flex gap-3 pt-1 stock-item-row" v-for="stockItem in item.reservation_groups" :key="stockItem.name">
                <div class="col-3 fw-bold pt-1">{{stockItem.name}}</div>
                <div class="col" v-for="vatType in vatTypesArray" :key="vatType">
                    <FormItem v-if="stockItem.reservation_group_products[vatType]" type="livesearch" :id="'group-'+stockItem.id+'-'+vatType" v-model="stockItem.reservation_group_products[vatType].external_pos_id"
                        :options="posArticles" idField="id" :content="stockItem.reservation_group_products[vatType].pos_name" @onchange="(a, b) => stockItem.reservation_group_products[vatType].pos_name = b" />
                    <input v-else type="text" class="w-100" inert="" /> 
                </div>
            </div>
        </Accordion>
        <Accordion id="sixthree" icon="fa-calendar-check" name="location.res_paymethods" :nameArgument="getReservationName" v-if="item.reservation_system.sync_payments">
            <div class="col pe-1" v-for="(paymethod, pindex) in paymentMethodDetails" :key="paymethod.name">
                <hr v-show="pindex != 0" class="bg-secondary" />
                <div class="col-12 fw-bold">{{paymethod.name}}</div>
                <div class="d-flex pt-1">
                    <div class="paymethod-grid col-10">
                        <div v-for="(listItem, lid) in paymethod.list" :key="listItem" class="pe-5">
                            <button class="btn btn-sm text-danger pt-0 px-1" type="button" @click="removePayMethod(paymethod.list, lid)"> <i class="fa fa-close"></i> </button> {{listItem.name}}
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <button type="button" class="btn btn-unidark btn-sm" @click="showPayMethodPopup(paymethod)"> <i class="fa fa-plus"></i> </button>
                    </div>
                </div>
            </div>
        </Accordion>
    </section>
    <section v-if="step == 8" class="mb-5">
        <p>U heeft alle stappen doorlopen. Klik op "Opslaan & Afsluiten" om de klant {{!isNew ? 'bij te werken' : 'toe te voegen'}}.</p>

        <p v-show="!item.debtor_number"> <i class="fa fa-exclamation-triangle text-warning me-2"></i>{{ $t('location.debtornr_not_set') }}</p>
        
        <template v-if="item.bookkeeping_system_active">
            <label class="d-flex col-sm-4">Test dagstaat</label>
            <div class="row mb-3">
                <div class="col-sm-2">
                    <FormItem id="yesterdayDate" type="date" v-model="yesterdayDate" />
                </div>
                <FormItem type="download" :service="testDaystate" :label="'Dagstaat ophalen'" class="col-auto" style="padding-start: 14px" endTag="fal" />
                <div class="col-2 pt-1">
                    <span v-if="item.daystate_connection_status == 'error'" class="text-danger">
                        <i class="fa fa-close me-2"></i>{{item.daystate_connection_status_message}}
                    </span>
                    <span v-else-if="item.daystate_connection_status == 'active'" class="text-success">
                        <i class="fa fa-check me-2"></i>Dagstaat ophalen gelukt
                    </span>
                </div>
            </div>
            <div class="row mb-2" v-if="item.daystate_connection_status == 'active'">
                <label class="d-flex required col-sm-3" for="source_autosync">{{$t('location.source_autosync')}}</label>
                <div class="col-sm-2 d-flex" style="justify-content: space-between;">
                    <FormItem id="source_autosync" type="slider" v-model="item.source_autosync" :options="[1,0]" />
                </div>
            </div>
        </template>
    </section>
    <div class="justify-content-between mt-3 sticky-bottom" :class="{'d-none': step == 0, 'd-flex': step != 0}">
        <div>
            <button type="button" class="btn btn-unidark prev-button" @click="prev" v-show="(!isLoggedInAsCustomer && step != 1) || (isLoggedInAsCustomer && step != 2)">
                <i class="far fa-chevron-left"></i>{{$t('form.previous')}}
            </button>
        </div>
        <div>
            <button type="button" class="btn btn-unilight save-button px-4 me-2" :disabled="isSaving" @click="save('overview')">
                <i class="far fa-save me-2"></i>{{$t('form.save')}}
            </button>
            <button type="button" class="btn btn-unidark next-button ms-2" @click="next" v-show="step != maxStep">
                <i class="far fa-chevron-right"></i>{{$t('form.next')}}
            </button>
        </div>
    </div>

    <CustomerFormPopup ref="popup" @save="addPayMethod" @changeName="setPayMethodName">
        <p v-if="activeAllPaymentOptionsChosen">Alle opties zijn gekozen</p>
        <template v-else>
            <h2 class="mb-2">{{$t('location.choose_accountlinks', ['unTill'])}}</h2>
            <FormItem v-for="pm in activePaymentOptions.filter(methd => !methd.disabled)" :key="pm.id" type="checkbox-with-value" :id="'refcb-'+pm.id" v-model="popupModel.values"
                     :content="pm.name" :label="pm.name" idField="name" />
        </template>
    </CustomerFormPopup>
</template>

<script>
    import locationService from '@/services/CustomerService';
    import dataStore from '@/store/data';
    import store from '@/store/user';
    import daystateService from '@/services/DaystateService';
    import integrationService from '@/services/IntegrationService';
    import franchiseService from '@/services/FranchiseService';
    import hospitalityGroupService from '@/services/HospitalityGroupService';
    import dealerService from '@/services/DealerService';
    import Accordion from '@/components/Accordion.vue';
    import CustomerFormPopup from './CustomerFormPopup.vue';
    import LogoUpload from '@/components/LogoUpload.vue';
    export default {
        name: 'CustomerDetail',
        components: {
            Accordion,
            CustomerFormPopup,
            LogoUpload
        },
        data() {
            return {
                item: {
                    "name": "",
                    "icon_image_data": null,
                    "hospitality_group_id": null,
                    "franchise_id": null,
                    "dealer_id": null,
                    "currency_id": 1,
                    "debtor_number": "",
                    "source_connection": {
                        "connection_type": "broker",
                        "ip_address": "",
                        "port": "",
                        "broker_uuid": "",
                        "username": "",
                        "password": "",
                        "database_name": "",
                        "source_id": 1,
                        "version": null
                    },
                    "bookkeeping_system_location": {
                        "country": "nl",
                        "status": "inactive",
                        "bookkeeping_system_auth": {
                            "id": null
                        },
                    },
                    "bookkeeping_system": null,
                    "reservation_system": {
                        "id": null,
                        "sync_payments": 0
                    },
                    "reservation_system_locations": [],
                    "reservation_system_active": 0,
                    "bookkeeping_system_active": 1,
                    "reservation_groups": [],
                    "payment_methods": [],
                    "package_language": 'nl',
                    "stockitems": [ {values: []} ],
                    "location_card_and_vouchers": [],
                    "cash_registers": [],
                    "payment_terminals": [],
                    "sales_areas": [],
                    "suppliers": [],
                    "sync_paid_only": 1,
                    "split_on_costcenter": 0,
                    "split_on_division": 0,
                    "sync_cards_vouchers": 0,
                    "sync_invoices": 0,
                    "sync_debtors": 0,
                    "book_withdrwals": 0,
                    "show_tip": 0,
                    "allow_gift_cards": 0,
                    "allow_client_cards": 0,
                    "allow_smart_cards": 0,
                    "allow_vouchers": 0,
                    "check_daystates": 0,
                    "responsible_person_required": 0,
                    "daystate_cash_mode": null,
                    "daystate_check_per": null,
                    "daystate_connection_status": null,
                    'default_paymethod': {glaccount: null},
                    'default_turnover': {glaccount: null},
                    'default_vat': {code: null},
                    'default_paymethod_glaccount': null,
                    'default_paymethod_glaccount_external_id': null,
                    'default_turnover_glaccount': null,
                    'default_turnover_glaccount_external_id': null,
                    'default_vatcode': null,
                    'default_vat_external_id': null,
                    'default_vat_is_inclusive': false,
                    'default_vat_percentage': null
                },
                prevItem: {},
                hours: Array.from({ length: 24 }, (_, i) => ({ id: i, name: i.toString().padStart(2, '0') })),
                minutes: Array.from({ length: 4 }, (_, i) => ({ id: (i*15), name: (i*15).toString().padStart(2, '0') })),
                dayStartHour: 6,
                dayStartMinute: 0,
                bookkeepingSet: 0,
                reservationsSet: 0,
                hasCashCounting: 0,
                hasCashAccountability: 0,
                sources: [],
                sourceName: "",
                franchises: [],
                hospitality_groups: [],
                currencies: [],
                dealers: [],
                suppliers: [],
                bookKeepingSystems: [],
                reservationSystems: [],
                bookkeepingSystemsById: {},
                reservationSystemsById: {},
                bookkeepingGlAccounts: [],
                bookkeepingDivisions: [],
                posSalesAreas: [],
                posArticles: [],
                vatTypes: [],
                journals: [],
                cardVouchers: [{name: "Cadeaukaarten", field: "allow_gift_cards", index: 0}, {name: "Klantenkaarten", field: "allow_client_cards", index: 1}, {name: "Smartcards", field: "allow_smart_cards", index: 2}, {name: "Vouchers", field: "allow_vouchers", index: 3}],
                cardVoucherTypes: {"3": {name: "Kruispost EFT", field: 'crosspost_eft_glaccount'}},
                terminalNames: [],
                cashRegNames: [],
                paymentMethodDetails: {"0": {name: "Cash", list: []}, "1": {name: "Banktransfer", list: []}, "2": {name: "Creditcard", list: []}, "3": {name: "Debitcard", list: []}},
                paymentMethodDetailOptions: [],
                paymentMethodDetailsDaystate: {"0": {name: "Cash", list: []}, "1": {name: "EFT", list: []}},
                paymentMethodDetailOptionsDaystate: [],
                activePaymentOptions: [],
                allPaymentOptionsChosen: false,
                allPaymentOptionsChosenDaystate: false,
                activeAllPaymentOptionsChosen: false,
                popupModel: {item: {}, values: []},
                stockItems: ["Bier", "Warme drank", "Lunchkaart", "Hapjes", "Wijn"],
                vatTypesArray: [21, 9, 0],
                tableMin: 1,
                tableMax: 100,
                isLoggedInAsDealer: false,
                isLoggedInAsCustomer: false,
                isNew: true,
                isSaving: false,
                isLoading: false,
                isProcessingCallback: false,
                isGettingDivision: false,
                isGettingGlAccounts: false,
                isGettingVatCodes: false,
                isGettingBookkeepingData: false,
                isGettingPosSalesAreas: false,
                isGettingPaymentMethods: false,
                isGettingTurnoverGroups: false,
                bookkeepingStatusResult: null,
                selectedDivision: null,
                yesterdayDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
                step: 1,
                activeUntil: 1,
                maxStep: 8
            }
        },
        watch: {
            selectedDivision(newVal) {
              if (newVal) {
                const divisionName = this.getDivisionNameByCode(newVal);
                this.item.bookkeeping_system_location.division_code = newVal;
                this.item.bookkeeping_system_location.division_name = divisionName;
              }
            },
            // Watch for changes on item.reservation_system.sales_area
            'item.reservation_system.external_sales_area_id': function(newVal, oldVal) {
                // Code to execute when sales_area changes
                console.log(`Sales area changed from ${oldVal} to ${newVal}`);
                if(typeof(newVal) == 'undefined' || newVal == oldVal) return;
                // Find the sales area from the posSalesAreas array by id
                const salesArea = this.posSalesAreas.find(area => area.id === newVal);
                if(salesArea) { // Setting tables
                    this.item.reservation_system.tablenumber_min = salesArea.tables.from;
                    this.tableMin = salesArea.tables.from;
                    this.item.reservation_system.tablenumber_max = salesArea.tables.to;
                    this.tableMax = salesArea.tables.to;
                }
            }
        },
        computed: {
            getBookkeepingName() {
                const auth = this.item && this.item.bookkeeping_system_location ? this.item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id : null;
                return auth && this.bookkeepingSystemsById[auth] ? this.bookkeepingSystemsById[auth].name : this.$t('locationtabs.step_5');
            },
            getReservationName() {
                return this.reservationSystemsById[this.item.reservation_system.reservation_system_id] ? this.reservationSystemsById[this.item.reservation_system.reservation_system_id].name : '';
            }
        },
        methods: {
            validate(actionAfter){
                //Validation
                if(this.step == 4 && this.item.check_daystates && !this.hasCashCounting && !this.hasCashAccountability){
                    alert('Kies alstublieft of u kascontrole of kasverantwoording wilt gebruiken.');
                    return;
                }
                if(this.step == 3 && this.item.bookkeeping_system_active && !this.item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id){
                    alert("Kies alstublieft een boekhoudpakket.");
                    return false;
                }
                if(this.step == 2 && actionAfter == 'overview' && !this.item.source_connection.password){
                    return true;
                }
                const els = document.querySelectorAll("section input[type='text']:not(.optional), section input[type='password'], section select:not(.optional)");
                let valid = true;
                els.forEach(el => {
                    if(!el.value){
                        el.classList.add("is-invalid");
                        if(!el.classList.contains("has-onfocus")){
                            el.addEventListener("focus", (thisEl) => thisEl.target.classList.remove("is-invalid") );
                            el.classList.add("has-onfocus");
                        }
                        valid = false;
                    }
                })
                return valid;
            },
            save(actionAfter = false, saveUntil = false) {
                if(!this.validate(actionAfter)){
                    return false;
                }
                return new Promise((resolve, reject) => {
                    this.isSaving = true;
                    this.item.step = this.step >= this.activeUntil ? this.step : this.activeUntil;
                    let updateData = this.hardCopy(this.item);
                    if(updateData.payment_methods){
                        updateData.payment_methods.forEach((method) => {
                            if(!method.bookkeeping_glaccount) return;
                            method.bookkeeping_glaccount_external_id = method.bookkeeping_glaccount.external_id;
                            method.bookkeeping_glaccount = method.bookkeeping_glaccount.glaccount;
                        });
                        for(let listIndex in this.paymentMethodDetails){
                            this.paymentMethodDetails[listIndex].list.forEach((method) => {
                                const thisUpdateData = updateData.payment_methods.find((pm) => pm.name == method.name);
                                if(thisUpdateData){
                                    thisUpdateData.source_payment_type = listIndex;
                                }
                            });
                        }
                        for(let listIndex in this.paymentMethodDetailsDaystate){
                            this.paymentMethodDetailsDaystate[listIndex].list.forEach((method) => {
                                const thisUpdateData = updateData.payment_methods.find((pm) => pm.name == method.name);
                                if(thisUpdateData){
                                    thisUpdateData.payment_type = listIndex;
                                }
                            });
                        }
                    }
                    if(updateData.turnover_groups){
                        updateData.turnover_groups.forEach((group) => {
                            if(group.bookkeeping_glaccount){
                                group.bookkeeping_glaccount_external_id = group.bookkeeping_glaccount.external_id;
                                group.bookkeeping_glaccount = group.bookkeeping_glaccount.glaccount;
                            }
                            if(group.bookkeeping_vatcode){
                                group.bookkeeping_vat_percentage = group.bookkeeping_vatcode.percentage;
                                group.bookkeeping_vat_is_inclusive = group.bookkeeping_vatcode.is_inclusive;
                                group.bookkeeping_vat_external_id = group.bookkeeping_vatcode.external_id;
                                group.bookkeeping_vatcode = group.bookkeeping_vatcode.code;
                            }
                        });
                    }
                    if(updateData.default_paymethod){
                        updateData.default_paymethod_glaccount_external_id = updateData.default_paymethod.external_id;
                        updateData.default_paymethod_glaccount = updateData.default_paymethod.glaccount;
                        updateData.default_turnover_glaccount_external_id = updateData.default_turnover.external_id;
                        updateData.default_turnover_glaccount = updateData.default_turnover.glaccount;
                        updateData.default_vat_percentage = updateData.default_vat.percentage;
                        updateData.default_vat_is_inclusive = updateData.default_vat.is_inclusive;
                        updateData.default_vat_external_id = updateData.default_vat.external_id;
                        updateData.default_vatcode = updateData.default_vat.code;
                    }
                    if(updateData.suppliers){
                        updateData.suppliers.forEach((sup) => {
                            if(sup.external_glaccount_id && typeof sup.external_glaccount_id == 'object'){
                                sup.external_glaccount_external_id = sup.external_glaccount_id.external_id;
                                sup.external_glaccount_id = sup.external_glaccount_id.glaccount;
                            }
                        });
                    }
                    updateData.reservation_payment_methods = [];
                    for(let i in this.paymentMethodDetails){
                        updateData.reservation_payment_methods.push({id: this.paymentMethodDetails[i].id ? this.paymentMethodDetails[i].id : null, payment_type: i, name: this.paymentMethodDetails[i].name})
                    }
                    if(updateData.bookkeeping_system_active){
                        updateData.bookkeeping_system_locations[0] = updateData.bookkeeping_system_location;
                    }
                    if(updateData.reservation_system_active){
                        updateData.reservation_system_locations[0] = updateData.reservation_system;
                    }
                    if(updateData.reservation_groups){
                        updateData.reservation_groups.forEach((group) => {
                            group.reservation_group_products = Object.values(group.reservation_group_products).filter((product) => product.external_pos_id);
                        });
                    }
                    if(updateData.check_daystates){
                        this.cardVouchers.toReversed().forEach((card) => {
                            if(!updateData[card.field]){
                                updateData.location_card_and_vouchers.splice(card.index, 1);
                            }
                        });
                        updateData.location_card_and_vouchers.forEach((card) => {
                            for(let field in card){
                                if(card[field] && typeof(card[field]) == 'object'){
                                    card[field] = card[field].glaccount;
                                }
                            }
                        });
                    }
                    updateData.sales_areas = updateData.sales_areas.filter((area) => (area.external_divison || area.cost_center_name));
                    if (this.hasCashCounting) this.item.daystate_cash_mode = 'counting';
                    if (this.hasCashAccountability) this.item.daystate_cash_mode = 'accountability';
                    if(saveUntil){
                        updateData.save_until = saveUntil;
                    }

                    locationService.updateOrStore(updateData).then(response => {
                        this.item.id = response.data.id;
                        delete this.item.step;
                        if(response.data.reservation_system_locations && response.data.reservation_system_locations[0]){
                            this.item.reservation_system.id = response.data.reservation_system_locations[0].id;
                            if(response.data.reservation_system_locations[0].reservation_system_auth){
                                this.item.reservation_system.reservation_system_auth.id = response.data.reservation_system_locations[0].reservation_system_auth.id;
                            }
                        }
                        if(actionAfter == 'overview'){
                            this.$router.push('/locaties');
                        }
                        else if(actionAfter == 'oauth'){
                            this.oauth(true);
                        }
                        else{
                            this.isSaving = false;
                            this.prevItem = JSON.stringify(this.item);
                        }
                        resolve(true);
                    }).catch(e => {
                        this.isSaving = false;
                        console.log("Error saving", e);
                        this.toastError(e);
                        resolve(false);
                    });
                });
            },
            prev() {
                if(this.step == 8 && this.item.reservation_system.reservation_system_id === null) this.step--;
                if(this.step == 7 && !this.item.bookkeeping_system_active) this.step--;
                if(this.step == 6 && !this.item.check_daystates) this.step--;
                if(this.step == 5 && !this.item.bookkeeping_system_active) this.step--;
                this.step--;
            },
            next() {
                //Check if equal
                let isEqual = JSON.stringify(this.item) == this.prevItem;
                if((!isEqual && this.save() === false) || !this.validate()){
                    return;
                }
                if(this.step == 3 && !this.item.bookkeeping_system_active) this.step++;
                if(this.step == 4 && !this.item.check_daystates) this.step++;
                if(this.step == 5 && !this.item.bookkeeping_system_active) this.step++;
                if(this.step == 6 && !this.item.reservation_system.reservation_system_id) this.step++;
                this.step++;
                this.activeUntil = Math.max(this.activeUntil, this.step);
                if(this.step == 7 && this.item.reservation_groups.length == 0){
                    this.getReservationData();
                }
            },
            setStep(step){
                if(this.activeUntil >= step){
                    this.step = step;
                }
            },
            resetBookkeepingData(setBookkeeping = true){
                if(!this.bookkeepingSet && setBookkeeping){
                    this.bookkeepingSet = 1;
                    return;
                }
                else if(setBookkeeping === false){
                    if(!confirm("Let op: als u het boekhoudpakket wijzigt, worden de ingevulde gegevens verwijderd. Weet u zeker dat u het boekhoudpakket wilt wijzigen?")){
                        return;
                    }
                    this.item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id = null;
                    this.bookkeepingSet = false;
                }
                this.item.bookkeeping_system_location.journal = null;
                this.item.bookkeeping_system_location.journal_glaccount = null;
                this.item.bookkeeping_system_location.crosspost_glaccount = null;
                this.item.bookkeeping_system_location.default_glaccount = null;
                this.item.bookkeeping_system_location.tip_glaccount = null;
                for(let pmethod in this.item.payment_methods){
                    this.item.payment_methods[pmethod].bookkeeping_glaccount = null;
                }
                for(let tgroup in this.item.turnover_groups){
                    this.item.turnover_groups[tgroup].bookkeeping_glaccount = null;
                    this.item.turnover_groups[tgroup].bookkeeping_vatcode = null;
                }
                this.item.bookkeeping_system_location.vat_per_costcenter = 0;
            },
            setHeaderText(){
                const prefix = (this.isNew ? 'Nieuwe locatie' : 'Locatie');
                const franchise = this.item.franchise_id ? (' - ' + this.item.franchise.name) : '';
                const name = this.item.name ? (' - ' + this.item.name) : '';
                return prefix + franchise + name;
            },
            updateDayStart() {
                if (this.dayStartHour !== null && this.dayStartMinute !== null) {
                    this.item.day_start = `${this.dayStartHour.toString().padStart(2, '0')}:${this.dayStartMinute.toString().padStart(2, '0')}`;
                }
            },
            setBookSysOptional(e){
                if(!this.item.bookkeeping_system_active) this.item.bookkeeping_system.bookkeeping_system_auth.bookkeeping_system_id = null;
            },
            setResSystemOptional(e){
                if(!this.item.reservation_system_active) this.item.reservation_system.reservation_system_id = null;
            },
            setExternalId(item, component, field){
                item[field + '_external_id'] = component ? component.external_id : null;
            },

            oauth(isSaved = false){
                this.isAuthenticating = true; // front end moet het juiste boekhoudpakket opslaan, pakt nu altijd Exact boekhoudpakket.
                if(!isSaved){
                    this.save('oauth', 'integration');
                    return;
                }
                integrationService.getAuthUrl(this.item.id, 'twinfield').then(response => {
                    location.assign(response.data.url)
                }).catch(e => this.toastError(e) );
            },

            removeBookkeeping(){
                this.item.bookkeeping_system_location.bookkeeping_system_auth = {};
            },
            getDivisions(setSelectedDivision = false) {
                this.isGettingDivision = true;
                integrationService.getDivisions(this.item.id).then(response => {
                    console.log("response", response);
                    this.bookkeepingDivisions = response.data;
                    this.isGettingDivision = false;
                    if(setSelectedDivision && response.data[0]){
                        this.selectedDivision = response.data[0].code;
                    }
                }).catch(e => this.toastError(e)
                ).finally(() => this.isGettingDivision = false );
            },

            async getCurrentDivision() {
                this.isGettingDivision = true;
                try {
                    const saveResult = await this.save();
                    if (saveResult) {
                        integrationService.getCurrentDivision(this.item.id).then(response => {
                            console.log("response", response, this.item.bookkeeping_system_location);
                            this.item.bookkeeping_system_location = response.data;
                            this.bookkeepingStatusResult = 'success';
                        }).catch(e => {
                            this.toastError(e);
                            this.bookkeepingStatusResult = 'error';
                        }).finally(() => this.isGettingDivision = false );
                    } else {
                        console.error('Save operation failed');
                        this.isGettingDivision = false;
                    }
                } catch (error) {
                    console.error('An error occurred:', error);
                    this.isGettingDivision = false;
                }
            },

            getBookkeepingData() {
                if(!this.item.id || !this.item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id) return;
                this.isGettingBookkeepingData = true;
                integrationService.getBookkeepingData(this.item.id).then(response => {
                    console.log("response", response.data);

                    this.bookkeepingGlAccounts = response.data.glaccounts;
                    this.bookkeepingGlAccounts.forEach((acc) => {
                        acc.name = `${acc.glaccount} - ${acc.description}`;
                        acc.id = {glaccount: acc.glaccount, external_id: acc.external_id ? acc.external_id : null};
                    });

                    this.vatTypes = response.data.vatcodes;
                    this.vatTypes.forEach((type) => {
                        type.name = `${type.code} - ${type.description}`;
                        type.id = {code: type.code, external_id: type.external_id ? type.external_id : null, percentage: type.percentage ? type.percentage : null, is_inclusive: type.is_inclusive ? type.is_inclusive : false};
                    });
                    this.journals = response.data.journals;
                    this.journals.forEach((journal) => journal.name = `${journal.code} - ${journal.description}`);

                    this.isGettingBookkeepingData = false;
                }).catch(e => this.toastError(e)
                ).finally(() => this.isGettingBookkeepingData = false );
            },
            updatePaymentMethods() {
                this.isGettingPaymentMethods = true;
                integrationService.getPaymentMethods(this.item.id).then(response => {
                    this.item.payment_methods = response.data;
                    this.isGettingPaymentMethods = false;
                }).catch(e => this.toastError(e)
                ).finally(() => this.isGettingPaymentMethods = false );
            },
            updateTurnoverGroups() {
                this.isGettingTurnoverGroups = true;
                integrationService.getTurnoverGroups(this.item.id).then(response => {
                    this.item.turnover_groups = response.data;
                    this.isGettingTurnoverGroups = false;
                }).catch(e => this.toastError(e) )
                .finally(() => this.isGettingTurnoverGroups = false );
            },
            setFranchise(e){
                const franchise = this.franchises.find(f => f.id == e.target.value);
                this.item.franchise = {name: franchise ? franchise.name : ''};
                if(franchise && franchise.hospitality_group_id) this.item.hospitality_group_id = franchise.hospitality_group_id;
                if(franchise && franchise.dealer_id) this.item.dealer_id = franchise.dealer_id;
            },
            setReservation(){
                this.item.reservation_system_active = 1;
                if(!this.item.reservation_system.reservation_system_auth) this.item.reservation_system.reservation_system_auth = {};
                if(!this.reservationsSet){
                    this.reservationsSet = 1;
                    return;
                }
                this.item.reservation_system.external_sales_area_id = null;
                this.item.reservation_system.external_initial_order_id = null;
                this.item.reservation_system.external_payment_id = null;
                this.item.reservation_system.sync_deposit = 0;
                this.item.reservation_system.external_deposit_payment_id = null;
                this.item.reservation_system.sync_products = 0;
                this.item.reservation_system.sync_payments = 0;
                for(let groupIndex in this.item.reservation_groups){
                    this.vatTypesArray.forEach((vatType) => {
                        if(this.item.reservation_groups[groupIndex])
                            this.item.reservation_groups[groupIndex].reservation_group_products[vatType] = {external_pos_id: null, pos_name: null};
                    });
                }
                for(let pmethodIndex in this.paymentMethodDetails){
                    this.paymentMethodDetails[pmethodIndex].list = [];
                }
                this.$nextTick(() => {
                    this.item.reservation_system.tablenumber_min = this.tableMin;
                    this.item.reservation_system.tablenumber_max = this.tableMax;
                    this.updatePayMethodOptions();
                });
            },
            showPayMethodPopup(paymethod, isForDaystate = false){
                this.popupModel = {item: paymethod, values: []};
                this.activePaymentOptions = isForDaystate ? this.paymentMethodDetailOptionsDaystate : this.paymentMethodDetailOptions;
                this.activeAllPaymentOptionsChosen = isForDaystate ? this.allPaymentOptionsChosenDaystate : this.allPaymentOptionsChosen;
                this.$refs.popup.trigger(paymethod, !isForDaystate);
            },
            addPayMethod(){
                const maxValue = this.popupModel.item.list.reduce((max, item) => item > max ? item : max, 0);
                this.popupModel.values.forEach((value, vindex) => this.popupModel.item.list.push({id: maxValue + vindex, name: value}));
                this.popupModel.item.list.sort((a, b) => a.name > b.name);
                this.popupModel = {item: {}, values: []};
                this.updatePayMethodOptions();
            },
            removePayMethod(paymethod, index){
                paymethod.splice(index, 1);
                this.updatePayMethodOptions();
            },
            setPayMethodName(name){
                this.popupModel.item.name = name;
                this.updatePayMethodOptions();
            },
            updatePayMethodOptions(){
                let allSelectedOptions = [], allSelectedOptionsDaystate = [];
                this.allPaymentOptionsChosen = true;
                this.allPaymentOptionsChosenDaystate = true;
                Object.values(this.paymentMethodDetails).forEach(paymethod => allSelectedOptions = allSelectedOptions.concat(paymethod.list));
                Object.values(this.paymentMethodDetailsDaystate).forEach(paymethod => allSelectedOptionsDaystate = allSelectedOptionsDaystate.concat(paymethod.list));
                this.paymentMethodDetailOptions.forEach(option => {
                    option.disabled = allSelectedOptions.find(selected => selected.name == option.name);
                    if(!option.disabled) this.allPaymentOptionsChosen = false;
                });
                this.paymentMethodDetailOptionsDaystate.forEach(option => {
                    option.disabled = allSelectedOptionsDaystate.find(selected => selected.name == option.name);
                    if(!option.disabled) this.allPaymentOptionsChosenDaystate = false;
                });
            },
            async testConnection(){
                this.item.source_connection.status = 'pending';
                if(!this.validate() || await this.save('connection') === false){
                    this.item.source_connection.status = null;
                    return;
                }
                return integrationService.testConnection(this.item.id).then(response => {
                    console.log("Response", response);
                    if(response.data == false){
                        this.item.source_connection.status = 'error';
                        return;
                    }
                    this.item.source_connection = response.data;
                    if(this.item.source_connection.status == 'active'){
                        this.updatePaymentMethods();
                        this.updateTurnoverGroups();
                    }

                }).catch(e => {
                    console.log(e);
                    this.item.source_connection.status = 'error';
                });
            },
            async testReservationConnection(){
                this.item.reservation_system.status = 'pending';
                if(!this.validate() || await this.save('integration') === false){
                    return;
                }
                return integrationService.testReservationConnection(this.item.id).then(response => {
                    console.log("Response", response);
                    if(response.data == false){
                        this.item.reservation_system.status = 'error';
                        return;
                    }
                    this.item.reservation_system = response.data;
                    this.getReservationData();
                }).catch(e => {
                    console.log(e);
                    this.item.reservation_system.status = 'error';
                });
            },
            async testDaystate(){
                this.item.daystate_connection_status = 'pending';
                return daystateService.testConnection(this.item.id, this.yesterdayDate).then(response => {
                    console.log("Response", response);
                    this.item.daystate_connection_status = response.data.status;
                    this.item.daystate_connection_status_message = response.data.message;
                }).catch(e => {
                    console.log(e);
                    this.item.source_connection.status = 'error';
                });
            },
            setGroupDealer(e){
                this.item.franchise_id = null;
                const group = this.hospitality_groups.find(g => g.id == e.target.value);
                if(group && group.dealer_id) this.item.dealer_id = group.dealer_id;
            },
            getLocation(locationId, isCallback = false){
                this.isNew = locationId == 'nieuw';
                if(!this.isNew){
                    this.isLoading = true;
                    locationService.show(locationId).then(response => {
                        this.item = response.data;
                        this.dayStartHour = parseInt(this.item.day_start.split(':')[0]);
                        this.dayStartMinute = parseInt(this.item.day_start.split(':')[1]);
                        this.item.source_connection = this.item.source_setting;

                        if(!this.item.bookkeeping_system_location) {
                            this.item.bookkeeping_system_location = { status: 'inactive', country: 'nl', bookkeeping_system_auth: {} };
                        }
                        this.item.bookkeeping_system_active = this.item.bookkeeping_system_locations.length == 0 ? 0 : 1;
                        this.item.reservation_system_active = this.item.reservation_system_locations.length == 0 ? 0 : 1;
                        this.bookkeepingSet = this.item.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system_id ? 1 : 0;
                        this.reservationsSet = this.item.reservation_system_active ? 1 : 0;
                        this.hasCashCounting = this.item.daystate_cash_mode == 'counting' ? 1 : 0;
                        this.hasCashAccountability = this.item.daystate_cash_mode == 'accountability' ? 1 : 0;

                        if(!this.item.package_language)
                            this.item.package_language = 'nl';
                        if(!this.item.bookkeeping_system_active)
                            this.item.bookkeeping_system = {bookkeeping_system_auth_id: null, id: null, bookkeeping_system_auth: {id: null, bookkeeping_system_id: null}};
                        if(!this.item.reservation_system_active)
                            this.item.reservation_system = {reservation_system_id: null, id: null, sync_payments: 0};
                        else
                            this.item.reservation_system = this.item.reservation_system_locations[0];
                        if(!this.item.reservation_system.reservation_system_auth)
                            this.item.reservation_system.reservation_system_auth = {};

                        this.getData();
                        this.paymentMethodDetailOptions = this.hardCopy(this.item.payment_methods);
                        this.paymentMethodDetailOptionsDaystate = this.hardCopy(this.item.payment_methods);
                        if(this.item.reservation_payment_methods.length){
                            this.paymentMethodDetails = {};
                            this.item.reservation_payment_methods.forEach(method => {
                                this.paymentMethodDetails[method.payment_type] = {id: method.id, name: method.name, list: []};
                            });
                        }
                        this.item.payment_methods.forEach(method => {
                            if(this.paymentMethodDetails[method.source_payment_type])
                                this.paymentMethodDetails[method.source_payment_type].list.push(method);
                            if(this.paymentMethodDetailsDaystate[method.payment_type])
                                this.paymentMethodDetailsDaystate[method.payment_type].list.push(method);
                        });
                        this.updatePayMethodOptions();
                        const getNewCardType = function(cardIndex, parent){ return {id: null, card_type: parent.cardVouchers[cardIndex].name};};
                        this.setFillableTable(this.cardVouchers, this.item.location_card_and_vouchers, getNewCardType, 'card_type', 'name');
                        const stepNames = {'algemeen': 1, 'verbinding': 2, 'integratie': 3, 'synchronisatie': 4, 'boekhoudpakket': 5, 'reserveringssysteem': 6, 'afronden': this.item.reservation_system.reservation_system_id ? 7 : 6};
                        const hash = window.location.hash ? window.location.hash.substring(1) : false;
                        this.step = hash && stepNames[hash] ? stepNames[hash] : 1;
                        const savedStep = Math.min(response.data.step, this.maxStep);
                        delete this.item.step;
                        this.prevItem = JSON.stringify(this.item);
                        if(isCallback){
                            this.step = 3;
                            this.activeUntil = 3;
                            this.isProcessingCallback = false;
                            window.history.pushState({"html": "","pageTitle": ""}, "", location.origin + "/locaties/" + locationId);
                        }
                        else if(!hash){
                            this.step = savedStep;
                        }
                        if(this.isLoggedInAsCustomer && this.step <= 1){
                            this.step = 2;
                        }
                        if(this.step == 4 && !this.item.bookkeeping_system_active) this.step++;
                        if(this.step == 5 && !this.item.check_daystates) this.step++;
                        if(this.step == 6 && !this.item.bookkeeping_system_active) this.step++;
                        if(this.step == 7 && !this.item.reservation_system.reservation_system_id) this.step++;
                        this.activeUntil = this.step;
                        if(this.step == 8){ //If all steps are done, show first step upon entering
                            this.step = 1;
                            this.activeUntil = 8;
                        }
                        this.isLoading = false;
                    });
                }
                else{
                    this.step = 1;
                    this.activeUntil = 1;
                    if(this.isLoggedInAsDealer) this.item.dealer_id = store.getters.getUser.dealer_id;
                    this.getData();
                }
            },
            setFillableTable(dataArray, fillArray, newItem, compareField1, compareField2){
                for(let areaIndex in dataArray){
                    const existingIndex = fillArray.findIndex(area => area[compareField1] == dataArray[areaIndex][compareField2]);
                    const existingItem = existingIndex > -1 ? this.hardCopy(fillArray[existingIndex]) : null;
                    if(existingItem) fillArray.splice(existingIndex, 1);
                    const newIndex = fillArray.push(newItem(areaIndex, this));
                    if(existingItem) fillArray[newIndex - 1] = existingItem;
                }
            },
            getData(){
                this.sources = dataStore.getters.getSources;
                const source = this.sources.find(s => s.id == this.item.source_id);
                this.sourceName = source ? source.name : '';
                franchiseService.index().then(response => this.franchises = response.data);
                hospitalityGroupService.index().then(response => this.hospitality_groups = response.data);
                dealerService.index().then(response => this.dealers = response.data);
                this.currencies = dataStore.getters.getCurrencies;
                this.bookKeepingSystems = dataStore.getters.getBookkeepingSystems;
                this.reservationSystems = dataStore.getters.getReservationSystems;
                this.bookKeepingSystems.forEach(system => this.bookkeepingSystemsById[system.id] = system);
                this.reservationSystems.forEach(system => this.reservationSystemsById[system.id] = system);
                if(!this.item.id) return;
                if(this.item.source_connection.status == 'active') {
                    this.getPosSalesAreas();
                    this.getPosArticles();
                    this.getSuppliers();
                }
                if(this.item.default_paymethod_glaccount){
                    this.item.default_paymethod = {glaccount: this.item.default_paymethod_glaccount};
                    this.item.default_turnover = {glaccount: this.item.default_turnover_glaccount};
                    this.item.default_vat = {code: this.item.default_vatcode};
                }
                const bookAuth = this.item.bookkeeping_system_location ? this.item.bookkeeping_system_location.bookkeeping_system_auth : null;
                if(bookAuth && (bookAuth.access_token || bookAuth.password || bookAuth.api_key)){
                    this.getBookkeepingData();
                    if(this.item.split_on_division){
                        this.getDivisions();
                    }
                }
                if(this.item.reservation_system && this.item.reservation_system.status == 'active') {
                    this.getReservationData();
                }
            },
            getPosSalesAreas() {
                if(!this.item.id) return;
                this.isGettingPosSalesAreas = true;
                integrationService.getPosSalesAreas(this.item.id).then(response => {
                    console.log("response pos sales areas", response.data);
                    this.posSalesAreas = response.data;
                    this.posSalesAreas.forEach((acc) => {
                        acc.name = `${acc.name}`;
                        acc.tables = acc.tables.length > 0 ? acc.tables[0] : {};
                        if(!this.isNew && acc.id == this.item.reservation_system.external_sales_area_id){
                            this.tableMin = acc.tables.from;
                            this.tableMax = acc.tables.to;
                        }
                    });
                    const getNewAreaType = function(i, parent){ return {external_id: parent.posSalesAreas[i].id, external_name: parent.posSalesAreas[i].name, external_number: parent.posSalesAreas[i].number, external_cost_center_id: null, cost_center_name: null, external_divison: null};};
                    this.setFillableTable(this.posSalesAreas, this.item.sales_areas, getNewAreaType, 'external_id', 'id');
                    this.item.sales_areas.sort((a, b) => a.external_number > b.external_number);
                }).catch(e => this.toastError(e)
                ).finally(() => this.isGettingPosSalesAreas = false );
            },
            getPosArticles() {
                if(!this.item.id) return;
                this.isGettingPosArticles = true;
                integrationService.getPosArticles(this.item.id).then(response => {
                    console.log("response pos articles", response.data);
                    this.posArticles = response.data;
                }).catch(e => this.toastError(e)
                ).finally(() => this.isGettingPosArticles = false );
            },
            getSuppliers() {
                if(!this.item.id) return;
                integrationService.getSuppliers(this.item.id).then(response => {
                    this.suppliers = response.data;
                    console.log("suppliers", response.data);
                    const getNewSuppType = function(i, parent){ return {name: parent.suppliers[i].name, external_glaccount_id: null, external_glaccount_external_id: null, withdraw_from_safe: 0, external_id: parent.suppliers[i].id, glaccount_name: null};};
                    this.setFillableTable(this.suppliers, this.item.suppliers, getNewSuppType, 'external_id', 'id');
                }).catch(e => this.toastError(e));
            },
            getReservationData(){
                if(!this.item.id) return;
                const reservationGroupCopy = this.hardCopy(this.item.reservation_groups);
                integrationService.getReservationGroups(this.item.id).then(response => {
                    this.item.reservation_groups = [];
                    for(let stock of response.data){
                        const newIndexId = this.item.reservation_groups.push({external_reservation_group_id: stock.id, name: stock.name, reservation_group_products: {}}) - 1;
                        for(let vat of this.vatTypesArray){
                            this.item.reservation_groups[newIndexId].reservation_group_products[vat] = {vat_percentage: vat, external_pos_id: null};
                        }
                        const foundStckItem = reservationGroupCopy.find(group => group.external_reservation_group_id == stock.id);
                        if(foundStckItem){
                            this.item.reservation_groups[newIndexId].id = foundStckItem.id;
                            const reservationGroupProducts = this.item.reservation_groups[newIndexId].reservation_group_products;
                            for(let groupProduct of foundStckItem.reservation_group_products){
                                const vat = parseInt(groupProduct.vat_percentage);
                                reservationGroupProducts[vat].external_pos_id = groupProduct.external_pos_id;
                                reservationGroupProducts[vat].pos_name = groupProduct.pos_name;
                                reservationGroupProducts[vat].id = groupProduct.id;
                            }
                            this.item.reservation_groups[newIndexId].reservation_group_products = reservationGroupProducts;
                        }
                    }
                }).catch(e => {
                    this.toastError(e);
                    //Failsafe
                    this.item.reservation_groups = reservationGroupCopy;
                    let groupLength = this.item.reservation_groups.length - 1;
                    for(let g = groupLength; g >= 0; g--){
                        let products = this.hardCopy(this.item.reservation_groups[g].reservation_group_products);
                        this.item.reservation_groups[g].reservation_group_products = {};
                        for(let vat of this.vatTypesArray){
                            const product = products.find(product => product && product.vat_percentage == vat);
                            if(!product){
                                this.item.reservation_groups[g].reservation_group_products[parseInt(vat)] = {vat_percentage: vat, external_pos_id: null};
                            }
                            else{
                                this.item.reservation_groups[g].reservation_group_products[parseInt(vat)] = product;
                            }
                        }
                    }
                });
            },
            setDaystateCheckDate(){
                if(this.item.check_daystates){
                    this.item.daystate_check_date = new Date().toISOString().split('T')[0];
                }
            },
            addName(list){
                list.push({id: null, name: '', order: list.length});
            },
            removeName(list, index){
                list.splice(index, 1);
            },
            safeFind(value, list, idfield = 'id', namefield = 'name'){
                const result = list.find(item => item[idfield] == value);
                return result ? result[namefield] : '';
            },
            hardCopy(item){
                return JSON.parse(JSON.stringify(item));
            },
            getDivisionNameByCode(code) {
                const division = this.bookkeepingDivisions.find(division => division.code === code);
                return division ? division.description : null;
            },
            determineType() {
              switch (this.getBookkeepingName) {
                case 'Exact Online':
                case 'Unit4':
                  return 'livesearch';
                default:
                  return 'text';
              }
            },

        },
        mounted(){
            this.step = 0;
            const locationId = this.$route.params.id;
            this.isLoggedInAsCustomer = store.getters.getUserType == 2;
            this.isLoggedInAsDealer = store.getters.getUserType == 3;
            //Not customers without locations allowed
            if(this.isLoggedInAsCustomer && !dataStore.getters.getLocationsToShow){
                this.$router.push({name: 'Home'});
                return;
            }
            if(locationId == "callback"){
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');
                const apiLocationId = urlParams.get('state');
                this.isLoading = true;
                this.isProcessingCallback = true;
                integrationService.processCallback(apiLocationId, code).then(response => {
                    this.getLocation(apiLocationId, true);
                }).catch(e => this.toastError(e) );
            }
            else{
                this.getLocation(locationId);
            }
        }
    }

</script>
<style scoped>
section .row{
    margin-bottom: .75rem
}
.ctype-label{
    width: 50%;
    border: 1px solid var(--uni-gray);
    background-color: white;
    padding: 1rem 2rem;
    transition: color 0.1s
}
.ctype-label.active{
    border-color: var(--uni-light) !important
}
.ctype-label:not(.active){
    color: #ccc !important
}
.ctype-label img{
    transition: filter 0.1s
}
.ctype-label .pill{
    transition: background-color 0.1s
}
.ctype-details{
    padding: 1rem 2rem
}
.ctype-details i{
    width: 1.5rem;
    padding-top: 2px;
    padding-bottom: 8px;
    transition: color 0.1s
}
.ctype-label:not(.active) .ctype-details i{
    color: #ccc !important
}
.ctype-label:not(.active) .pill{
    background-color: #ccc !important
}
.ctype-label:not(.active) img{
    filter: brightness(160%);
}
.ctype-details span{
    font-weight: 500;
}
.radio-tab-3{
    vertical-align: top;
    position: relative;
    top: 1.5rem
}
.paymethod-grid{
    display: grid;
    gap: 0 1rem;
    grid-template-columns: repeat(5, 1fr);
    min-height: 25px
}
.fa-info-circle + div{
    position: absolute;
    translate: 8.5rem -5%;
    color: white;
    opacity: 1
}
.fa-info-circle:not(:hover) + div{
    display: none !important;
}
</style>
