<template>
    <div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header justify-content-between">
                    <template v-if="changingName">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Naam betaalmethode wijzigen
                        </h5>
                    </template>
                    <template v-else>
                        <h5 class="modal-title" id="exampleModalLabel">
                            {{moduleName}}
                        </h5>
                        <div>
                            <button type="button" class="btn btn-unidark me-2" v-show="showChangeButton" @click.prevent="changingName = true">Naam betaalmethode wijzigen</button>
                            <button type="button" class="btn-close pt-3" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </template>
                </div>
                <div class="modal-body" v-if="changingName">
                    <p>Let op! Dit heeft gevolgen voor de verdere instellingen in het systeem. De naam moet gelijk zijn aan de betaalmethode in SEM.</p>
                    <FormItem type="text" id="rtex-npmn" v-model="newName" label="Nieuwe naam betaalmethode" />
                </div>
                <div class="modal-body" v-show="!changingName">
                    <slot></slot>
                </div>
                <div class="modal-footer" v-if="changingName"> 
                    <button id="backbutton" type="button" class="btn btn-secondary" @click.prevent="changingName = false">Terug</button> 
                    <button id="savebutton" type="button" class="btn btn-success" @click.prevent="changeName">Opslaan</button> 
                </div>
                <div class="modal-footer" v-else> 
                    <button id="savebutton" type="button" class="btn btn-success" data-bs-dismiss="modal" @click.prevent="save">Opslaan</button> 
                </div>
            </div>
        </div>
    </div>
    <button class="d-none" data-bs-toggle="modal" data-bs-target="#formModal" id="formModalTrigger"></button>
</template>

<script>
    export default {
        name: 'CustomerFormPopup',
        emits: ['save', 'changeName'],
        data () {
			return {
				moduleName: '',
                changingName: false,
                showChangeButton: false,
                newName: '',
                values: []
			}
		},
        methods: {
            save() {
                this.$emit("save", this.values);
            },
            trigger(item, showNameChangeButton = false) {
                this.moduleName = item.name;
                this.newName = item.name;
                this.changingName = false;
                this.showChangeButton = showNameChangeButton && this.$parent.getReservationName == 'Sem';
                document.getElementById('formModalTrigger').click();
            },
            changeName(){
                this.changingName = false;
                this.moduleName = this.newName;
                this.$emit("changeName", this.newName);
            }
        }
    }

</script>
<style scoped>
#formModal {
    z-index: 2222
}
</style>